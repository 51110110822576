import { Box, Button } from "@mui/material";
import Style from "./welcome.module.scss";
import rightArrow from "../../../assets/images/rightArrow.svg";
import welcomeProfileImage from "../../../assets/images/homeModule/welcomeProfile.png";
import { useLocation, useNavigate } from "react-router-dom";
import defaultBg from "../../../assets/images/homeModule/welcomeBg.png";
import { useEffect, useState } from "react";
import { WelcomeModel } from "../model/welcomeModel";
import ReactPlayer from "react-player/lazy";
import {
  getPurchaseProgramAPICall,
  getUserDetail,
  getWelcomeDataAPICall,
  setBoostCompleteOnbordingAPICall
} from "../homeService/homeService";
import DisplayImage from "../../../shared/components/displayImage/displayImage";
import { Spinner } from "../../../shared/components/spinner/spinner";
import environment from "../../../environment";
import { getYoutubeVideoId } from "../../../shared/js/getYoutubeVideoId";
import WavesurferSingle from "../../../shared/components/player/wavesurferSingle";
import { ReactComponent as MonoLogo } from "../../../assets/images/mono-logo.svg";
import { handleToastMessage } from "../../../shared/js/handleToastMessage";
import { ReactComponent as PlayButtonIcon } from "../../../assets/images/play-button.svg";
import unstoppableBackground from "../../../assets/images/dashboardModule/unstoppable/unstoppable.png";
import empowermentExplainer from "../../../assets/images/empowerment-explainer.png";
import boostExplainer from "../../../assets/images/boost-explainer.png";
import unstoppableExplainer from "../../../assets/images/unstoppable-explainer.png";

const _welcomeModel = new WelcomeModel();

const Welcome = () => {
  const [welcomeData, setWelcomeData] = useState({ ..._welcomeModel });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { title, backgroundImage, page } = state || {};
  const [isLoader, setIsLoader] = useState(true);
  const [isShowAuthorInformation, setIsShowAuthorInformation] = useState(false);
  const [isWatchCompleteVideo, setIsWatchCompleteVideo] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [videoId, setVideoId] = useState("");
  const [isPlayYoutubeVideo, setIsPlayYoutubeVideo] = useState(false);

  useEffect(() => {
    async function getDetail() {
      getUserDetail()
      let _planId;
      switch (page) {
        case "boost":
          _planId = 1;
          setThumbnail(boostExplainer);
          break;
        case "empowerment":
          _planId = 2;
          setThumbnail(empowermentExplainer);
          break;
        case "unstoppable":
          _planId = 3;
          setThumbnail(unstoppableExplainer);
          break;
        default:
          setIsShowAuthorInformation(true);
          setIsWatchCompleteVideo(true);
          let _welcomeData = await getWelcomeDataAPICall();
          setWelcomeData({ ..._welcomeData.welcomeData });
          setIsLoader(false);
          return;
      }
      await getWelcomeData(_planId);
      setIsLoader(false);
    }
    getDetail()
  }, [page]);

  const setThumbnailVideo = (videoUrl) => {
    let _id = getYoutubeVideoId(videoUrl);
    setVideoId(_id);
  };

  const handleYoutubeVideo = () => {
    setIsPlayYoutubeVideo(true);
  };

  const getWelcomeData = async (planId) => {
    let _response = await getPurchaseProgramAPICall();
    if (_response.isSuccess) {
      let _plan = _response.purchaseProgramList.find(
        (plan) => plan.id === planId
      );
      let _welcomeData = new WelcomeModel();
      const { authorName, authorDesignation, imageUrl, introVideo } = _plan;
      _welcomeData = {
        authorName: authorName,
        authorDesignation: authorDesignation,
        image: `${imageUrl}`,
        video: introVideo,
      };
      setWelcomeData({ ..._welcomeData });
      setThumbnailVideo(_welcomeData.video);
      setIsLoader(false);
    }
  };
  const handleCompleteVideo = async() => {
    let _response = false;

    if(page === 'boost'){
      _response = await setBoostCompleteOnbordingAPICall(1);
    }else if(page === 'empowerment'){
      _response = await setBoostCompleteOnbordingAPICall(2);
    }else if(page === 'unstoppable'){
      _response = await setBoostCompleteOnbordingAPICall(3);
    }
    if(_response){
      setIsWatchCompleteVideo(true);
    }
  };

  const handleNextPage = () => {
    if (!isWatchCompleteVideo) {
      handleToastMessage("warning", "Please watch complete video first");
      return;
    }
    if (page) {
      navigate("/case-studies", {
        state: {
          page,
        },
      });
    } else {
      navigate("/choose-reason");
    }
  };

  return (
    <div
      className={`container-fluid ${Style.mainContainer}`}
      style={{
        backgroundImage: `url('${backgroundImage || defaultBg}')`,
      }}
    >
      <div className="container">
        <span className={`d-flex justify-content-center ${Style.welcome}`}>
          Welcome {title}
        </span>
        {isLoader ? (
          <div className={Style.spiner}>
            <Spinner isWhite />
          </div>
        ) : (
          <div className="row mt-4 pt-3">
            <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
              <div className={`${Style.leftContainer}`}>
                {!isShowAuthorInformation ? (
                  <div className="d-flex justify-content-center">
                    <MonoLogo className={`${Style.monoLogo}`} />
                  </div>
                ) : (
                  <Box className={`d-flex flex-column align-items-center`}>
                    <div className={`${Style.imageContainer}`}>
                      <DisplayImage
                        imageUrl={welcomeData.image}
                        className="rounded-circle w-100 h-100"
                      />
                    </div>

                    <h3 className="mt-3">{welcomeData.authorName}</h3>
                    <span>{welcomeData.authorDesignation}</span>
                  </Box>
                )}
              </div>
            </div>
            <div
              className={`col-lg-7  col-md-12 col-sm-12 mb-2 d-flex justify-content-end ${Style.rightPortion}`}
            >
  
              <div className={` ${Style.videoContainer}`}>
                {isShowAuthorInformation && (
                  <div className={`${Style.audioContainer} d-flex`}>
                    <div className={`${Style.audio} d-flex`}>
                      <WavesurferSingle
                        mode={"unstoppable"}
                        container={`welcome123`}
                        dashboardHomeMusic={welcomeData.video}
                      />
                    </div>
                  </div>
                )}
                {!isShowAuthorInformation && (
                  <>
                    {!isPlayYoutubeVideo ? (
                      <div className={Style.thumnailContainer}>
                        <div
                          className={`${Style.playbutton} cursor-pointer`}
                          onClick={handleYoutubeVideo}
                        >
                          <PlayButtonIcon height={45} />
                        </div>
                        <img src={thumbnail} draggable="false" />
                      </div>
                    ) : (
                      <ReactPlayer
                        onReady={() => {
                          setIsVideoReady(true);
                        }}
                        className={"video-container"}
                        height={"97%"}
                        width={"100%"}
                        playing={true}
                        controls={false}
                        onEnded={handleCompleteVideo}
                        url={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                      />
                    )}
                  </>

                  // <iframe
                  //
                  //   id="welcome-video-container"
                  //   src={welcomeData.video}
                  // ></iframe>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isLoader ? (
        <div className={`d-flex justify-content-end  ${Style.buttonContainer}`}>
          <Button
            onClick={handleNextPage}
            className={`mt-4 white-btn  text-transform-none ${Style.submitButton}`}
          >
            Next
            <span className="ms-2">
              <img src={rightArrow} width="30px" />
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default Welcome;
