import './App.scss';
import React from 'react';
import AppRouting from './appRouting';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/theme.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <React.StrictMode>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AppRouting />
    </React.StrictMode>
  );
}

export default App;
