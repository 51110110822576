import React from "react";
import Style from "./socialAuth.module.scss";
// import GoogleLogin from "react-google-login";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import googleLogo from "../../assets/images/signup/google.svg";
import { SocialSigninModel } from "../../modules/auth/model/socialSigninModel";

const GoogleAuth = ({ handleAuthData }) => {
  
  const responseGoogle = (res) => {
    let _authData = new SocialSigninModel();
    _authData = {
      email: res?.profileObj.email,
      token: res.googleId,
      platform: "google",
    };
    handleAuthData?.(_authData);
  };

  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
  });

  return (
    <div>
      {/* <GoogleLogin
        clientId={clientId}
        render={(renderProps) => (
          <img
            className={`${Style.socialIcon}`}
            onClick={renderProps.onClick}
            src={googleLogo}
            alt="googleLogo"
          />
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      /> */}
        <button
          onClick={login}
          style={{border: 'none', alignItems: 'center', backgroundColor: 'transparent'}}
          >
           <img
           className={`${Style.socialIcon}`}
            src={googleLogo}
            alt="googleLogo"
          />
        </button>
    </div>
  );
};

export default GoogleAuth;
