import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

import betterEnv from "./env.json"

/*------------- firebase object production ---------------------*/
const firebaseConfig = { ...betterEnv.firebase.config };
/* ------------------------------------------------------------*/

const app = initializeApp(firebaseConfig);
console.log*("99999", getApps().length)
if (!getApps().length) {
    initializeApp(firebaseConfig);
}
export const auth = getAuth(app);
auth.settings.appVerificationDisabledForTesting = true; // Disable for testing (only in development)
console.log("7777777777", auth)