import React, { useState } from "react";
import { Container } from "@mui/material";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import instagramLogo from "../../../../assets/images/signup/instagram.svg";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleAuth from "../../../../api/socialAuth/googleAuth";
import Style from "./signupForm.module.scss";
import FacebookAuth from "../../../../api/socialAuth/facebookAuth";
import InstagramAuth from "../../../../api/socialAuth/instagramAuth";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  requestOtp,
  setPromoCodeAPICall,
  singUpAPICall,
  socialLoginAPICall,
} from "../../authService/authService";
import CustomButton from "../../../../shared/components/customButton/customButton";
import { getUserActiveStatus } from "../../../../shared/js/getUserActiveStatus";
import { webFlowRoutes } from "../../../../shared/js/webFlowRoutes";
import { UserStatus } from "../../../../shared/js/enums";

import {
  getUserToken,
  setItemInLocalStorage,
  setUserToken,
} from "../../../../shared/js/userCredential";
import environment from "../../../../environment";
import CustomInput from "../../../../shared/components/customInput/customInput";

const SignupForm = ({ handleOtpPage }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const search = useLocation().search;
  const handleChange = (phoneNumber) => {
    console.log("@@@@@@", phoneNumber)
    // let _phoneNumber = "+" + country.dial +'_'+ phoneNumber;
    setPhoneNumber(phoneNumber);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let _isValidPhoneNumber = phoneNumber?.split('_').join('')
    if (isValidPhoneNumber(_isValidPhoneNumber)) {
      setIsLoading(true);
      let _isUserStoreInLocalStorage = false;

      const _isSuccess = await singUpAPICall(
        phoneNumber,
        _isUserStoreInLocalStorage
      );
      setIsLoading(false);
      if (_isSuccess) {
        setIsLoading(true);
        const _response = await requestOtp(_isValidPhoneNumber);
        setIsLoading(false);
        // if (_isSuccess) {
        //   if (_isSuccess) {
        //     // navigate("/otp");
        handleOtpPage?.(true, phoneNumber);
        //   }
        // }
      }
    } else {
      setIsValid(isValidPhoneNumber(phoneNumber));
    }
  };

  const handleSocialAuthData = async (userData) => {
    setIsLoading(true);
    let _isSuccess = await socialLoginAPICall(userData);
    setIsLoading(false);
    if (_isSuccess) {
      await setPromoCodeAPICall();
      let _redirectUrl = "/"
      let _isSeenRewardScreen = false;
      _isSeenRewardScreen = await JSON.parse(localStorage.getItem("userData"));
      if(_isSeenRewardScreen?.isBannerSeen){
        let _status = getUserActiveStatus();
        let _redirectUrl = webFlowRoutes.find(
          (routeObj) => routeObj.status === _status
        )?.route;

        if (_redirectUrl === "/") {
          _redirectUrl = "/email-verification";
        }
        if (_status === UserStatus.active) {
          const _paramsRedirectUrl = new URLSearchParams(search).get(
            "redirectUrl"
          );
          if (_paramsRedirectUrl) {
            _redirectUrl = _paramsRedirectUrl;
          }
        }
        navigate(_redirectUrl);
      }else{
        _redirectUrl = "/reward"
        navigate(_redirectUrl);
      }
    }
  };
  const handleExploreMore = async () => {
    let _token = getUserToken();
    if (!_token) {
      await setItemInLocalStorage("isGuest", true);
      await setUserToken(environment.guestToken);
    }
    navigate("/explore");
  };

  return (
    <div>
      <h4 className={`text-white text-center mb-1 ${Style.mainHeading}`}>
        Step towards your Happiness
      </h4>
      <Container className={`${Style.signupBox}`}>
        <h4 className={`text-white mt-3 ${Style.title}`}>Sign Up</h4>
        <p
          className={`text-white  text-center mx-auto ${Style.description} ${Style.fieldWidth}`}
        >
         Enter your phone number. We will send you a code for verification.
        </p>
        <form onSubmit={onSubmit}>
          <label
            className={`text-white  mb-1 text-start ${Style.phoneNumberLabel}`}
          >
            Phone Number
          </label>
           <PhoneInput
            defaultCountry={"US"}
            international={true}
            className={`form-control ${Style.phoneInput} d-flex justify-content-center`}
            onChange={handleChange}
            inputComponent={CustomInput}
          />
          {isValid ? (
            " "
          ) : (
            <label
            className={`text-danger mt-1 text-end ${Style.invalidPhoneNumberLabel}`}
            >
              invalid number
            </label>
          )}
          <div id="recaptcha_container"></div>
          <p className={`${Style.signWithText} text-white mt-2 mb-1`}>
            Or Signup With
          </p>
          <Container
            className={`d-flex py-2 justify-content-center ${Style.socialIconBox}`}
          >
            {/* <img className={`${Style.socialIcon}`} src={instagramLogo} alt="" /> */}

            {/* <div><InstagramAuth/></div> */}
            {/* <div>
              <InstagramAuth />
            </div> */}
            <div>
              <FacebookAuth handleAuthData={handleSocialAuthData} />
            </div>
            <div>
              <GoogleAuth handleAuthData={handleSocialAuthData} />
            </div>
          </Container>
          <CustomButton
            type="button"
            title="Explore more here"
            buttonStyle={Style.exploreButton}
            handleButtonClick={handleExploreMore}
          />
          <br />
          <CustomButton
            type="submit"
            title="Sign up for free trials"
            buttonStyle={Style.submitButton}
            isLoading={isLoading}
          />
        </form>
        <div className="d-flex justify-content-center mb-3">
          <span className=""><a href="https://blissiree.com/privacy-policy/" target="blank" className="fw-bold text-white text-decoration-underline">Privacy Policy </a></span>
          <span className="mx-1">{'&'}</span>
          <span><a href="https://blissiree.com/terms-and-conditions/" target="blank" className="fw-bold text-white text-decoration-underline"> Terms and Conditions</a></span>
        </div>
      </Container>
    </div>
  );
};
export default SignupForm;
