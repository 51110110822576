import React, { useState, useEffect, useRef } from "react";
import Style from "./wavesurfer.module.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTrackInPlaylist
} from "../../../redux/Action/wavesurfer/index.js";
import NewPlaylist from "../../../modules/dashboard/newPlaylist/newPlaylist.js";
import AnyDevice from "./modular/AnyDevice";
import { handleToastMessage } from "../../js/handleToastMessage.js";
import useNetworkStatus from "./useNetworkStatus.js";

const WavesurferModular = ({
  isControlButtons = false,
  playPauseButton = false,
  isPlayList = false,
  isVolume = false,
  isProgressbar = true,
  progressbar = 0.5,
  cursor = 10,
  handleSubscribePlan,
  setPlayerDOMReady,
  isPlayerInstantiated,
  ...props
}) => {
  const dispatch = useDispatch();
  
  const audioPlayerInstance = useSelector((state) => state.audioPlayer);
  const playlist = useSelector((state) => state.playlist);
  
  const playlistRef = useRef();
  const isLoopTrackRef = useRef();
  const isPlayerEventsBound = useRef(false);

  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoopTrack, setIsLoopTrack] = useState(false)
  const [canNext, setCanNext] = useState(false)
  const [canPrevious, setPrevious] = useState(false)
  const [track, setTrack] = useState()

  const [isNewPlaylistOpen, setIsNewPlaylistOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [displayedCurrentTime, setDisplayedCurrentTime] = useState("--:--");
  const [displayedTrackDuration, setDisplayedTrackDuration] = useState("--:--");

  const { isOnline } = useNetworkStatus();
  const [offlineTrack, setOfflineTrack] = useState()

  useEffect(() => {
    if (isPlayerInstantiated) {
      // Handle what happens when the player is instantiated
      const playIntent = playlistRef.current = playlist;
      const currentTrack = playIntent.playlist[playIntent.currentTrackIndex];

      // hold on to set track unless we check for internet availablity

      // Assigning this regradless of online/oflfine as an audio can fail loading if network fails during audio
      setOfflineTrack(currentTrack)

      if (isOnline) {
        setTrack(currentTrack);
        console.log(currentTrack)
        audioPlayerInstance.load(currentTrack?.url);
      } else {
        handleToastMessage("info", "No Internet Access. We will retry to play audio!");
      }

      // Set states for buttons forward or back if they can be interacted with
      setCycleButtonStates(playIntent);
    }
  }, [playlist])

  useEffect(() => {
    if (offlineTrack && isOnline) {
      console.log("Loading audio", offlineTrack?.url)
      setTrack(offlineTrack);
      setOfflineTrack(null);
      audioPlayerInstance.load(offlineTrack?.url);
    }
  }, [isOnline])

  const setCycleButtonStates = (playIntent) => {
    const length = playIntent.playlist.length;
    const trackIndex = playIntent.currentTrackIndex;

    setCanNext(playbackHandlers._isCyclingInbound(trackIndex + 1, length))
    setPrevious(playbackHandlers._isCyclingInbound(trackIndex - 1, length))
  }

  useEffect(() => {
    if (isPlayerInstantiated && !isPlayerEventsBound.current) {
      audioPlayerInstance.on('finish', playbackHandlers.onFinish)
      audioPlayerInstance.on('ready', (trackDuration) => playbackHandlers.onReady(trackDuration))
      audioPlayerInstance.on('play', playbackHandlers.onPlay)
      audioPlayerInstance.on('pause', playbackHandlers.onPause)
      audioPlayerInstance.on('loading', playbackHandlers.onLoad)
      audioPlayerInstance.on('timeupdate', (currentTime) => playbackHandlers.onTimeupdate(currentTime))
      // audioPlayerInstance.on('error', () => retryAudioLoad(track))

      isPlayerEventsBound.current = true
    }
  }, [isPlayerInstantiated])

  const favoriteAndNewPlaylistHandler = (isFavorite) => {
    setIsFavorite(isFavorite);
    setIsNewPlaylistOpen(true);
  };

  // How you bind some of the functions to event handlers varies. Refer to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind for more info
  const playbackHandlers = {
    // "Private" (?) methods
    _globalSeekTime: 30,
    _seek: function (duration) {
      audioPlayerInstance.skip(duration);
    },
    _isSeekInDuration: function (targetTime, duration) {
      return targetTime >= 0 && targetTime <= duration;
    },

    _cycleTracks: function (direction) {
      const playlistSize = playlist.playlist.length;
  
      if (playlistSize) {
        // direction is either forward (positive integer) or backward (negative integer) and determines how much (e.g.: 1 moves to the next track, 2 moves to the next 2 tracks, etc.)
        const nextTrackIndex = playlist.currentTrackIndex + direction;
  
        // Is the next track index to dispatch within the boundaries of the playlist? then set a new track index to dispatch
        if (this._isCyclingInbound(nextTrackIndex, playlist.playlist.length)) {
          dispatch(setCurrentTrackInPlaylist({ trackIndex: nextTrackIndex }));
        }
      }
    },
    _isCyclingInbound: (cyclingTo, length) => {
      return cyclingTo >= 0 && cyclingTo < length;
    },
    _secondsInNumberToStringTime: (secondsInNumber) => {
      const minutes = Math.floor(secondsInNumber / 60);
      const seconds = Math.abs(Math.floor(minutes - secondsInNumber) % 60);

      return String(minutes).padStart(2, 0) + ":" + String(seconds).padStart(2, 0)
    },

    // Methods
    rewind: function() {
      this._seek(0 - this._globalSeekTime);
    },
    forward: function() {
      this._seek(this._globalSeekTime);
    },

    previous: function() {
      this._cycleTracks(-1);
    },
    next: function() {
      this._cycleTracks(1);
    },

    playPause: () => { audioPlayerInstance.playPause() },
    play: function () { audioPlayerInstance.playPause() },
    pause: function () { audioPlayerInstance.playPause() },
    toggleLoop: function (newToggleState = null) {
      // If a new state is not defined, we will invert the existing state
      if (newToggleState === null) {
        newToggleState = !isLoopTrack
      }
  
      setIsLoopTrack(newToggleState);
      isLoopTrackRef.current = newToggleState;
    },
    setVolume: (newVolume) => {
      audioPlayerInstance.setVolume(newVolume);
    },

    // Actual wavesurfer events
    onReady: function (trackDuration) {
      console.log("--------", trackDuration)
      audioPlayerInstance.playPause()
      setDisplayedTrackDuration(this._secondsInNumberToStringTime(trackDuration));
    },
    onFinish: () => {
      const playlist = playlistRef.current;
      const isLoopTrack = isLoopTrackRef.current;

      if (isLoopTrack) {
        audioPlayerInstance.playPause()
      }
      else if (playlist.currentTrackIndex + 1 == playlist.playlist.length) {
        // Hit end of playlist. Not sure why I can't not have this block, but whatever. It works.
      }
      else {
        dispatch(setCurrentTrackInPlaylist({trackIndex: playlist.currentTrackIndex + 1}))
      }
    },
    onPause: () => {
      setIsPlaying(false);
    },
    onPlay: () => {
      setIsPlaying(true);
      setIsLoading(false)
    },
    onTimeupdate: function (currentTime) {
      // TODO: reduce the cycles and state updates to every second (this function updates almost 150+ times per second!)
      setDisplayedCurrentTime(this._secondsInNumberToStringTime(currentTime));
    },
    onLoad: (percent) => {
      if (!isLoading) {
        setIsLoading(true)
      }
    }
  }

  return (
    <div className={`${Style.container}`}>
      <div className={`d-flex align-items-center justify-content-between`}>
        <AnyDevice
          isPlaying={isPlaying}
          isLoading={isLoading}
          favoriteAndNewPlaylistHandler={favoriteAndNewPlaylistHandler}
          previousHandler={ () => playbackHandlers.previous() }
          rewindHandler={ () => playbackHandlers.rewind() }
          playPauseHandler={ () => playbackHandlers.playPause() }
          forwardHandler={ () => playbackHandlers.forward() }
          nextHandler={ () => playbackHandlers.next() }
          loopHandler={ (newToggleState) => playbackHandlers.toggleLoop(newToggleState) }
          canPrevious={canPrevious}
          canNext={canNext}
          volumeHandler={playbackHandlers.setVolume}
          track={track}
          currentTime={displayedCurrentTime}
          trackDuration={displayedTrackDuration}
          setPlayerDOMReady={setPlayerDOMReady}
          isPlayerInstantiated={isPlayerInstantiated} />
      </div>
      {
        isNewPlaylistOpen
        && <NewPlaylist 
            currentTrack={playlist.playlist[playlist.currentTrackIndex]}
            isOpen={isNewPlaylistOpen}
            isFavorite={isFavorite}
            isOpenHandler={setIsNewPlaylistOpen} />
      }
    </div>
  );
};
export default WavesurferModular;
