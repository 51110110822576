import React from "react";


const CustomInput = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  return (
    <input
      ref={ref} // Forward the ref to the actual input element
      value={value} // Controlled input value
      onChange={onChange} // onChange handler
      style={{
        background: 'transparent', // Custom styling, e.g., remove background
        border: '0px solid #ccc', // Optional: Custom border
        padding: '10px', // Optional: Custom padding
      }}
      {...rest} // Spread the rest of the props, such as type="tel"
    />
  );
});

export default CustomInput