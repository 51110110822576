import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Style from "./boost.module.scss";
import { Toolbar } from "@mui/material";
import AudioPlayerCard from "./audioPlayerCard/audioPlayerCard";
import { ReactComponent as NoticationIcon } from "../../../assets/images/notification.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import myMusic from "../../../assets/images/audioPlayer/testAudio.mp3";
import AudioList from "../audioList/audioList";
import { ReactComponent as LeftArrow } from "../../../assets/images/leftArrow.svg";
import WavesurferSingle from "../../../shared/components/player/wavesurferSingle";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as RightAngle } from "../../../assets/images/rightAngle.svg";
import boostBackgroundImage from "../../../assets/images/dashboardModule/empowerment/bg1.png";
import {
  getAllCategoriesAPICall,
  getPopularAudiosAPICall,
  getSuggestedPlaylistAudiosAPICall,
  getYourPlaylistAPICall,
} from "../dashboardService/boost";
import AllPlaylist from "./allPlayList/allPlaylist";
import { playListCategoryEnum, UserPlanStatuses } from "../../../shared/js/enums";
import { Spinner } from "../../../shared/components/spinner/spinner";
import {
  getHappierMoodAudio,
  getUserPlanStatus,
} from "../../auth/authService/authService";
import environment from "../../../environment";
import { getUserData } from "../../../shared/js/userCredential";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.8,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const categoriesPageSize = 5;
const suggestedAudiosPageSize = 20;
const Boost = ({ isGuest = false }) => {
  const musicList = useSelector((state) => state.musicList);
  const PreviousMusicList = useSelector((state) => state.PreviousMusicList);
  const previousMusicIndex = useSelector((state) => state.previousMusicIndex);
  const inActivePlayerIndex = useSelector((state) => state.inActivePlayerIndex);
  const yourPlaylistCount = useSelector((state) => state.yourPlaylistCount);

  const [isMobileTab, setIsMobileTab] = useState(false);
  const [isShowPlaylist, setIsShowPlaylist] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);

  const [yourPlaylist, setYourPlaylist] = useState([]);
  const [suggestedPlaylist, setSuggestedPlaylist] = useState([]);
  const [popularAudios, setPopularAudios] = useState([]);
  const [categories, setCategories] = useState([]);
  const [yourPlaylistLoader, setYourPlaylistLoader] = useState(true);
  const [suggestedPlaylistLoader, setSuggestedPlaylistLoader] = useState(true);
  const [popularAudiosLoader, setPopularAudiosLoader] = useState(true);
  const [categoriesLoader, setCategoriesLoader] = useState(true);
  const [averageSleepTime, setAverageSleepTime] = useState(0);
  const [homeAudioData, setHomeAudioData] = useState({});
  const { state } = useLocation();
  const { key } = state || {};

  useEffect(() => {
    async function getAudios() {
      let _result = await getHappierMoodAudio();
      if (_result.isSuccess) {
        if (_result.categoryAudiolist.length > 0) {
          setHomeAudioData(_result.categoryAudiolist[0]);
        }
      }
    }
    getAudios()
  }, []);

  useEffect(() => {
    handleCloseAllAudio();
  }, [key]);

  useEffect(() => {
    async function getPlanStatus() {
      let _averageSleep = await getUserPlanStatus();
      if (_averageSleep.isSuccess) {
        let time = _averageSleep.averageSleepTime;
        console.log("--------", time)
        if (time) {
          let timeArray = time?.split(":", 2);
          timeArray[0] = timeArray[0] + " h ";
          timeArray[1] = timeArray[1] + " min ";
          setAverageSleepTime(timeArray);
        }
      }
    }
    getPlanStatus()
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize());
  }, []);

  const handleResize = () => {
    setIsMobileTab(window.innerWidth <= 850);
  };

  useEffect(() => {}, [musicList]);
  const [yourPlaylistAudios, setYourPlaylistAudios] = useState([]);
  const [allAudioList, setAllAudioList] = useState([]);
  const [appBarTitle, setAppBarTitle] = useState("");
  const [suggestedAudiosOffset, setSuggestedAudiosOffset] = useState(0);
  const [totalSuggestedAudios, setTotalSuggestedAudios] = useState(0);
  const [isAllAudioVisible, setIsAllAudioVisible] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isPlaylistAudioVisible, setIsPlaylistAudioVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      let _boostStatus = await getUserData();
      console.log("££££££££", _boostStatus)
      if (_boostStatus) {
        switch (_boostStatus.onBordingStatus) {
          case UserPlanStatuses.onboarding:
            navigate('/welcome', {
              state: {title: 'to Boost', backgroundImage: boostBackgroundImage, page: 'boost'}
            });
            break;
          case UserPlanStatuses.pending:
            navigate('/premium', {
              state: { key: Math.random() }
            });
            break;
        }
      }
    }
    getData()
  }, []);

  useEffect(() => {
    async function getPlaylist() {
      if (!isGuest) {
        await getYourPlaylist(10, 0);
      }
    }
    getPlaylist()
  }, [yourPlaylistCount]);

  useEffect(() => {
    async function getPlaylist() {
      await getSuggestedPlayListAudios(
        suggestedAudiosPageSize,
        suggestedAudiosOffset
      );
    }
    getPlaylist()
  }, [suggestedAudiosOffset]);

  useEffect(() => {
    async function getPopAudios() {
      await getPopularAudioList(5, 0);
    }
    getPopAudios()
  }, []);

  useEffect(() => {
    async function getCategoryList(params) {
      await getCategoiresList(categoriesPageSize, 0, searchText);
    }
    getCategoryList()
  }, []);

  const getYourPlaylist = async (limit, offset, searchField = "") => {
    let _result = await getYourPlaylistAPICall(limit, offset, searchField);
    setYourPlaylistLoader(false);
    if (_result.isSuccess) {
      setYourPlaylist(_result.yourPlaylist);
      // setYourPlaylist(musicList.activePlayerIndex ===_result.playListId  ? musicList.activePlaylistArray : _result.yourPlaylist);
    }
  };

  const getSuggestedPlayListAudios = async (
    limit,
    offset,
    searchField = ""
  ) => {
    setSuggestedPlaylistLoader(true);
    let _result = await getSuggestedPlaylistAudiosAPICall(
      limit,
      offset,
      searchField
    );
    setSuggestedPlaylistLoader(false);
    if (_result.isSuccess) {
      let _suggestAudios =
        offset === 0
          ? [..._result.suggestedPlaylist]
          : [...suggestedPlaylist, ..._result.suggestedPlaylist];
      setSuggestedPlaylist(
        musicList.activePlayerIndex === _result.playListId
          ? musicList.activePlaylistArray
          : _suggestAudios
      );
      setTotalSuggestedAudios(_result.count);
    }
  };

  const getPopularAudioList = async (limit, offset, searchField = "") => {
    let _result = await getPopularAudiosAPICall(limit, offset, searchField);
    setPopularAudiosLoader(false);
    if (_result.isSuccess) {
      setPopularAudios(
        musicList.activePlayerIndex === _result.playListId
          ? musicList.activePlaylistArray
          : _result.popularAudiolist
      );
    }
  };

  const getCategoiresList = async (limit, offset, searchField = "") => {
    setIsCategoriesLoading(true);
    let _result = await getAllCategoriesAPICall(limit, offset, searchField);
    setCategoriesLoader(false);
    if (_result.isSuccess) {
      let _categorylist =
        offset === 0
          ? [..._result.categorylist]
          : [...categories, ..._result.categorylist];
      setCategories([..._categorylist]);
      //   setTotalCategories(
      //     musicList.activePlayerIndex === _result.playListId
      //       ? musicList.activePlaylistArray
      //       : _result.count
      //   );
    }
  };

  const handleAllPopularAudios = (title) => {
    setAppBarTitle(title);
    setSearchText("");
    setIsAllAudioVisible(true);
    setSelectedPlaylist(playListCategoryEnum.popularAudios);
  };

  const handleShowPlaylist = (selectedPlaylist, title) => {
    setSelectedPlaylist(selectedPlaylist);
    setAppBarTitle(title);
    setSearchText("");
    setIsShowPlaylist(true);
  };

  const handleYourPlayListAudios = async (playListId, title) => {
    setIsAllAudioVisible(true);
    setSearchText("");
    setAppBarTitle(title);
    setSelectedPlaylist(playListCategoryEnum.yourPlaylist);
    setSelectedPlaylistId(playListId);
  };
  const handleSeeAllSuggestedPlaylist = () => {
    setSearchText("");
    setAppBarTitle("Suggested playlist");
    setSelectedPlaylist(playListCategoryEnum.categoryList);
    setSelectedPlaylistId(playListCategoryEnum.categoryList);
    setIsShowPlaylist(true);
  };
  const handleSeeAllBoostAudioLibrary = () => {
    setIsAllAudioVisible(true);
    setSearchText("");
    setAppBarTitle("Boost Audio Library");
    setSelectedPlaylist(playListCategoryEnum.suggestedPlayList);
    setSelectedPlaylistId(playListCategoryEnum.suggestedPlayList);
  };

  const handleSingleCategoryAudio = async (categoryId, title) => {
    setIsAllAudioVisible(true);
    setSearchText("");
    setAppBarTitle(title);
    setSelectedPlaylist(playListCategoryEnum.categoryAudios);
    setSelectedPlaylistId(categoryId);
  };

  const handleCloseAllAudio = () => {
    if (isPlaylistAudioVisible) {
      setIsPlaylistAudioVisible(false);
      handleShowPlaylist(playListCategoryEnum.yourPlaylist, "Your playlist");
      setIsAllAudioVisible(false);
      return;
    }
    setIsAllAudioVisible(false);
    setIsShowPlaylist(false);
    setSearchText("");
    getYourPlaylist(5, 0);
    getSuggestedPlayListAudios(suggestedAudiosPageSize, 0);
    getPopularAudioList(5, 0);
    setSuggestedAudiosOffset(0);
  };
  const handleNotification = () => {
    navigate("/dashboard/notification");
  };

  const handleAudioFromPlaylist = (
    playlistId,
    playlistTitle,
    playlistIndicator
  ) => {
    if (playListCategoryEnum.categoryList === playlistIndicator) {
      handleSingleCategoryAudio(playlistId, playlistTitle);
      setIsShowPlaylist(false);
      return;
    }
    setIsPlaylistAudioVisible(true);
    setSelectedPlaylist(playlistIndicator);
    setSelectedPlaylistId(playlistId);
    setAppBarTitle(playlistTitle);
    setIsAllAudioVisible(true);
    setSearchText("");
    setIsShowPlaylist(false);
  };
  const scrollListner = (e) => {
    const scrolled =
      e.target.clientHeight + e.target.scrollTop + 10 > e.target.scrollHeight;
    if (scrolled) {
      if (
        !suggestedPlaylistLoader &&
        !isAllAudioVisible &&
        !isShowPlaylist &&
        totalSuggestedAudios > suggestedPlaylist.length
      ) {
        setSuggestedAudiosOffset((prev) => ++prev);
      }
    }
  };

  const handleSearchInput = (e) => {
    let _text = e.target.value;
    setSearchText(_text);
    if (!isAllAudioVisible && !isShowPlaylist) {
      getYourPlaylist(5, 0, _text);
      getSuggestedPlayListAudios(suggestedAudiosPageSize, 0, _text);
      getPopularAudioList(5, 0, _text);
      setSuggestedAudiosOffset(0);
      getCategoiresList(10, 0, _text);
    }
  };

  return (
    <div className={`${!isMobileTab ? Style.outletContainer : ""}`}>
      <div
        className={` d-flex justify-content-between ${Style.appBarContainer}`}
      >
        <div className={`d-flex align-items-center ${Style.appBarContent}`}>
          {(isShowPlaylist || isAllAudioVisible) && (
            <>
              <span onClick={handleCloseAllAudio} className="cursor-pointer">
                <LeftArrow width={35} fill={"white"} />
              </span>
              <h5 className={`m-0 ${Style.title}`}>{appBarTitle}</h5>
            </>
          )}
        </div>
        <div
          className={`d-flex align-items-center justify-content-end ${Style.rightPortion}`}
        >
          {(isAllAudioVisible || isShowPlaylist) ? (
            <Input
              placeholder="Title, artist or topic"
              className={`${Style.input}`}
              value={searchText}
              onChange={handleSearchInput}
              startAdornment={
                <InputAdornment
                  position="start"
                  className={`${Style.inputAdorment}`}
                >
                  <SearchIcon height={20} width={20} />
                </InputAdornment>
              }
            />
          ):(
            <Input
              placeholder="Title, artist or topic"
              className={`cursor-pointer ${Style.input}`}
              onClick={handleSeeAllBoostAudioLibrary}
              startAdornment={
                <InputAdornment
                  position="start"
                  className={`${Style.inputAdorment}`}
                >
                  <SearchIcon height={20} width={20} />
                </InputAdornment>
              }
            />
          )}
          {!isGuest && (
            <NoticationIcon onClick={handleNotification} height={30} />
          )}
        </div>
      </div>
      {!(isShowPlaylist || isAllAudioVisible) && (
        <div
          // onScroll={scrollListner}
          className={`container-fluid ${Style.boostContainer}`}
        >
          <>
            <div className={`${Style.contentContainer} mb-4 mx-auto`}>
              <div
                className={`${Style.mediaPlayerBox} position-relative`}
                style={{
                  backgroundImage: `url('${environment.serverUrl}${homeAudioData?.image}')`,
                }}
              >
                <div className={`${Style.mediaPlayer} position-relative`}>
                  <div className="mt-5">
                    {homeAudioData?.music && (
                      <WavesurferSingle
                        dashboardHomeMusic={homeAudioData?.music}
                        progressbar={0}
                        cursor={0}
                        boost={false}
                      />
                    )}
                    {/* <PlayButton height='45' /> */}
                  </div>
                </div>
                <Toolbar className={`justify-content-between`}>
                  <div className={`${Style.timer}`}>
                    {/* <span>00:10:00</span> */}
                    {!suggestedPlaylist?.length ? "00:00" : ""}
                  </div>
                  <div>
                    <span>Recommended Playlist</span>
                  </div>
                </Toolbar>
              </div>
              <Toolbar className={`justify-content-between`}>
                {averageSleepTime ? (
                  <>
                    <div>
                      <span>Average Sleep Time</span>
                    </div>
                    <div>
                      <span>{averageSleepTime}</span>
                    </div>
                  </>
                ) : null}
              </Toolbar>
            </div>

            {!isGuest && (
              <div className={`${Style.playlist}`}>
                <Toolbar className="justify-content-between">
                  <h5>Your playlist</h5>
                  <span
                    onClick={() =>
                      handleShowPlaylist(
                        playListCategoryEnum.yourPlaylist,
                        "Your playlist"
                      )
                    }
                    className="cursor-pointer"
                  >
                    See All
                    <RightAngle height={24} width={30} />
                  </span>
                </Toolbar>
                {!yourPlaylistLoader &&
                  (yourPlaylist.length ? (
                    <Carousel
                      responsive={responsive}
                      containerClass={Style.playListCarousel}
                      showDots={false}
                      swipeable={true}
                      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    >
                      {yourPlaylist.map((item, i) => {
                        return (
                          <div key={i} className={Style.playListCard}>
                            <AudioPlayerCard
                              musicList={yourPlaylist}
                              PreviousMusicList={PreviousMusicList}
                              inActivePlayerIndex={inActivePlayerIndex}
                              activePlayerIndex={musicList.activePlayerIndex}
                              previousIndex={previousMusicIndex}
                              index={i}
                              myPlayerIndex={1}
                              title={item.title}
                              isLock={item.isLock}
                              image={item.imageUrl}
                              veritcal={false}
                              isPlaylist={true}
                              handlePlaylist={() =>
                                handleYourPlayListAudios(item.id, item.title)
                              }
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <div className={Style.emptyList}>
                      No Audio is Available in the Playlist
                    </div>
                  ))}
                {yourPlaylistLoader && (
                  <div className={Style.spiner}>
                    <Spinner isWhite />
                  </div>
                )}
              </div>
            )}
            <div className={`${Style.playlist} mt-4`}>
              <Toolbar className="justify-content-between">
                <h5>Suggested Playlist</h5>
                <span
                  onClick={handleSeeAllSuggestedPlaylist}
                  className="cursor-pointer"
                >
                  See All
                  <RightAngle height={24} width={30} />
                </span>
              </Toolbar>
              {!categoriesLoader &&
                (categories.length ? (
                  <Carousel
                    responsive={responsive}
                    containerClass={Style.playListCarousel}
                    showDots={false}
                    swipeable={true}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  >
                    {categories.map((item, i) => (
                      <div key={i} className={Style.playListCard}>
                        <AudioPlayerCard
                          musicList={categories}
                          PreviousMusicList={PreviousMusicList}
                          activePlayerIndex={musicList.activePlayerIndex}
                          previousIndex={previousMusicIndex}
                          index={i}
                          myPlayerIndex={item.myPlayerIndex}
                          isPlay={item.isPlay}
                          title={item.name}
                          isLock={false}
                          isPlaylist={true}
                          image={item.imageUrl}
                          handlePlaylist={() =>
                            handleSingleCategoryAudio(item.id, item.name)
                          }
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className={Style.emptyList}>
                    No Audio is Available in the Playlist
                  </div>
                ))}
              {categoriesLoader && (
                <div className={Style.spiner}>
                  <Spinner isWhite />
                </div>
              )}
            </div>

            <div className={`${Style.playlist} mt-4`}>
              <Toolbar className="justify-content-between">
                <h5>Popular Audios</h5>
                <span
                  onClick={() => handleAllPopularAudios("Popular Audios")}
                  className="cursor-pointer"
                >
                  See All
                  <RightAngle height={24} width={30} />
                </span>
              </Toolbar>
              {!popularAudiosLoader &&
                (popularAudios.length ? (
                  <Carousel
                    responsive={responsive}
                    containerClass={Style.playListCarousel}
                    showDots={false}
                    swipeable={true}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  >
                    {popularAudios.map((item, i) => {
                      return (
                        <div key={i} className={Style.playListCard}>
                          <AudioPlayerCard
                            musicList={popularAudios}
                            PreviousMusicList={PreviousMusicList}
                            activePlayerIndex={musicList.activePlayerIndex}
                            previousIndex={previousMusicIndex}
                            index={i}
                            myPlayerIndex={item.myPlayerIndex}
                            isPlay={item.isPlay}
                            title={item.title}
                            isLock={item.isLock}
                            image={item.image}
                            veritcal={false}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div className={Style.emptyList}>
                    No Audio is Available in the Playlist
                  </div>
                ))}
              {popularAudiosLoader && (
                <div className={Style.spiner}>
                  <Spinner isWhite />
                </div>
              )}
            </div>
            <div className={`${Style.playlist} mt-4`}>
              <Toolbar className="justify-content-between">
                <h5>Boost Audio Library</h5>
                <span
                  onClick={handleSeeAllBoostAudioLibrary}
                  className="cursor-pointer"
                >
                  See All
                  <RightAngle height={24} width={30} />
                </span>
              </Toolbar>
              {suggestedPlaylist.length ? (
                <>
                <div
                  className={`d-flex flex-wrap ${Style.categoriesContainer}`}
                >
                  {suggestedPlaylist.map((item, i) => {
                    return (
                      <div key={i} className={`${Style.playListCard} mb-3`}>
                        <AudioPlayerCard
                          musicList={suggestedPlaylist}
                          PreviousMusicList={PreviousMusicList}
                          inActivePlayerIndex={inActivePlayerIndex}
                          activePlayerIndex={musicList.activePlayerIndex}
                          previousIndex={previousMusicIndex}
                          index={i}
                          myPlayerIndex={item.myPlayerIndex}
                          isPlay={item.isPlay}
                          title={item.title}
                          isLock={item.isLock}
                          image={item.image}
                          veritcal={false}
                          isPlaylist={false}
                        />
                      </div>
                    );
                  })}
                </div>
                  {(suggestedPlaylist.length >= 20) && 
                  <div className="d-flex justify-content-center mt-4 ">
                    <span className="cursor-pointer"
                      onClick={handleSeeAllBoostAudioLibrary}
                      >
                      See More . . .
                    </span>
                  </div>
                  }
                </>
              ) : (
                <>
                  {!suggestedPlaylistLoader && (
                    <div className={Style.emptyList}>
                      No Audio is Available in the Playlist
                    </div>
                  )}
                </>
              )}
              {suggestedPlaylistLoader && (
                <div className={Style.spiner}>
                  <Spinner isWhite />
                </div>
              )}
            </div>
          </>
        </div>
      )}
      {isShowPlaylist && (
        <AllPlaylist
          playListIndicator={selectedPlaylist}
          handleAudioFromPlaylist={handleAudioFromPlaylist}
          searchText={searchText}
        />
      )}
      {isAllAudioVisible && (
        <AudioList
          playlistId={selectedPlaylistId}
          playListIndicator={selectedPlaylist}
          searchText={searchText}
        />
      )}
    </div>
  );
};
export default Boost;
