import { useEffect, useState } from "react";
import { Box, AppBar, SpeedDial, SpeedDialAction } from "@mui/material";
import SideNavbar from "../../shared/components/sideNavbar/sideNavbar";
import { Outlet, useNavigate } from "react-router-dom";
import Style from "./dashboard.module.scss";
import homeDashboardBackground from "../../assets/images/dashboardModule/home/bg.svg";
import boostBackground from "../../assets/images/dashboardModule/boost/boost.png";
import empowermentBackground from "../../assets/images/dashboardModule/empowerment/bg.png";
import settingBackground from "../../assets/images/dashboardModule/settings/bg.png";
import chatBackground from "../../assets/images/chatBg.png";
import BottomNavbar from "../../shared/components/bottomNavbar/bottomNavbar";
import unstoppableBackground from "../../assets/images/dashboardModule/unstoppable/unstoppable.png";
import DiscountOfferWeb from "../../assets/images/DiscountOffer.png";
import DiscountOfferMobile from "../../assets/images/DiscountOfferMobile.png";
import { ReactComponent as HeadphoneIcon } from "../../assets/images/headphones.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/chat.svg";
import { ReactComponent as VideoChatIcon } from "../../assets/images/videoChat.svg";
import WaveSurfer from "wavesurfer.js";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import Environment from "../../environment";
import {
  endDateTimeActivityAPICall,
  getCheckHappinessScoreStatusAPICall,
  getSimulatorImagesAPICall,
  startDateTimeActivityAPICall,
} from "./dashboardService/dashboard";
import {
  getEntryId,
  getUserData,
  setEntryId,
} from "../../shared/js/userCredential";
import { getNotificationAPICall } from "./notification/notificationService/notificationService";
import { addUnSeenNotificationCount } from "../../redux/Action/notification";
import Badge from "@mui/material/Badge";
import {
  ref,
  onValue,
  push,
  child,
  getDatabase,
  get,
  update,
} from "firebase/database";
import { userTypeEnum } from "../../shared/js/enums";
import Popup from "../../shared/components/popup/popup";
import { getDiscountBannerStatus } from "../auth/authService/authService";
import WavesurferModular from "../../shared/components/player/wavesurferModular";
import { maybeSetPlayerInstance } from "../../redux/Action/wavesurfer";

const dbPath_Users = "/Users";
const dbPath_Chats = "/Chats";
const db = getDatabase();

const navigationBackground = [
  { route: "/dashboard", backgroundImage: homeDashboardBackground },
  { route: "/dashboard/boost", backgroundImage: boostBackground },
  { route: "/dashboard/empowerment", backgroundImage: empowermentBackground },
  { route: "/dashboard/unstoppable", backgroundImage: unstoppableBackground },
  { route: "/dashboard/settings", backgroundImage: settingBackground },
  { route: "/dashboard/notification", backgroundImage: unstoppableBackground },
  { route: "/dashboard/messages", backgroundImage: chatBackground },
  {
    route: "/dashboard/virtual-meeting",
    backgroundImage: chatBackground,
  },
  { route: "/dashboard/success-paid", backgroundImage: empowermentBackground },
  {
    route: "/dashboard/unstoppable-success-paid",
    backgroundImage: unstoppableBackground,
  },
  {
    route: "/dashboard/success-paid-virtual-meeting",
    backgroundImage: empowermentBackground,
  },
];

const Dashboard = () => {
  const [pageBackgroundImage, setPageBackgroundImage] = useState("");
  const _location = window.location?.pathname;
  const [isMobileTab, setIsMobileTab] = useState(false);
  const [isFloatingButtonOpen, setIsFloatingButtonOpen] = useState(false);
  const [isFloatingButtonDragable, setIsFloatingButtonDragable] = useState(false);
  const musicList = useSelector((state) => state.musicList);
  const navigate = useNavigate();
  const [btnDirection, setBtnDirection] = useState("up");
  const [isSubmitedHappinessToday, setIsSubmitedHappinessToday] =
    useState(false);
  const [adminId, setAdminId] = useState(null);
  const [unseenMessages, setUnseenMessages] = useState(0);
  const [isShowDiscountedPopup, setIsShowDiscountedPopup] = useState(false);

  const [wavesurferInstance, setWavesurferInstance] = useState(null);

  const [playerDOMReady, setPlayerDOMReady] = useState(false)

  const playerVisibilityState = useSelector((state) => state.playerVisibility);

  useEffect(() => {
    let _user = getUserDataAsync();
    setIsShowDiscountedPopup(!_user.isBannerSeen)
  }, []);

  //get cases function
  const getUserDataAsync = async () => {
    let _user = await getUserData();
    setIsShowDiscountedPopup(!_user.isBannerSeen)
  };

  useEffect(() => {
    getAdminId();
  }, []);
  useEffect(() => {
    if (adminId) {
      getUnCountMessages();
    }
  }, [adminId]);

  // The player instance is instantiated this up-high in the stack as its functionalities needs to be propagated to its users
  const instantiateWavesurfInstance = () => {
    if (!wavesurferInstance && playerDOMReady) {
      console.log("Hmm did wavesurferInstance get instantiated again");

      const createdWaveSurferInstance = WaveSurfer.create({
        container: "#player-wrap #player",
        cursorColor: "#ffffff",
        progressColor: "#ffffff",
        waveColor: "rgb(253, 254, 254,0.3)",
        cursorWidth: 10,
        barHeight: 0.5,
        height: 10,
        hideScrollbar: true,
      })

      dispatch(maybeSetPlayerInstance(createdWaveSurferInstance))

      setWavesurferInstance(createdWaveSurferInstance);
    }
  }

  useEffect(instantiateWavesurfInstance, [playerDOMReady]);

  const getUnCountMessages = async () => {
    let _user = await getUserData();
    let _chatKey = `${adminId}${_user.userId}`;
    const dbChatRef = ref(db, `${dbPath_Chats}/${_chatKey}`);
    onValue(dbChatRef, (snapshot) => {
      let _chats = snapshot.val();
      setUnseenMessages(_chats?.userUnseenCount);
    });
  };

  const getAdminId = () => {
    const dbRef = ref(db);
    get(child(dbRef, `${dbPath_Users}`))
      .then((snapshot) => {
        let _users = snapshot.val();
        _users = Object.entries(_users);
        let _user = _users.find((user) => user[1]?.type === userTypeEnum.admin);
        setAdminId(_user[1]?.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResize = () => {
    setIsMobileTab(window.innerWidth <= 850);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    getNotifications()
  }, []);
  
  const getNotifications = async () => {
    let _notificationsList = await getNotificationAPICall(5, 0);
    let _isSubmited = await getCheckHappinessScoreStatusAPICall();
    if (_notificationsList.success) {
      dispatch(addUnSeenNotificationCount(_notificationsList.unseen));
    }
    setIsSubmitedHappinessToday(_isSubmited.isSubmited);
  }

  useEffect(() => {
    addVisibilityChangeEvent()
  }, []);

  const addVisibilityChangeEvent = async () => {
    document.addEventListener("visibilitychange", async () => {
      let _day, _month, _year;
      let dataValue = new Date();
      _day =
        dataValue.getDate() > 9
          ? dataValue.getDate()
          : `0${dataValue.getDate()}`;
      _month =
        dataValue.getMonth() + 1 > 9
          ? dataValue.getMonth() + 1
          : `0${dataValue.getMonth() + 1}`;
      _year = dataValue.getFullYear();

      let _hours, _mints, _seconds;
      var d = new Date();
      _hours = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
      _mints = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
      _seconds = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;

      let _formatedDate = `${`${_year}-${_month}-${_day} ${_hours}:${_mints}:${_seconds}`}`;

      if (document.hidden) {
        endDateTimeActivityAPICall({
          endDate: _formatedDate,
          entryId: await getEntryId()?.toString(),
        });
      } else {
        let _entryId = await startDateTimeActivityAPICall(_formatedDate);
        setEntryId(_entryId?.data?.id);
      }
    });
  }

  useEffect(() => {
    window.addEventListener("beforeunload", async () => {
      let _day, _month, _year;
      let dataValue = new Date();
      _day =
        dataValue.getDate() > 9
          ? dataValue.getDate()
          : `0${dataValue.getDate()}`;
      _month =
        dataValue.getMonth() + 1 > 9
          ? dataValue.getMonth() + 1
          : `0${dataValue.getMonth() + 1}`;
      _year = dataValue.getFullYear();

      let _hours, _mints, _seconds;
      var d = new Date();
      _hours = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
      _mints = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
      _seconds = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;

      let _formatedDate = `${`${_year}-${_month}-${_day} ${_hours}:${_mints}:${_seconds}`}`;
      endDateTimeActivityAPICall({
        endDate: _formatedDate,
        entryId: await getEntryId()?.toString(),
      });
    });
  }, []);

  useEffect(() => {
    getSimulatorImages()
  }, [_location === "/dashboard"]);

  const getSimulatorImages = async () => {
    let _bgImages = await getSimulatorImagesAPICall();
    // morning 4 to 12  => 4am to 12pm
    // evening 12 to 20 => 12pm to 8pm
    // night 20 to 4    => 8pm to 4am
    var today = new Date();
    var curHr = today.getHours();
    if (curHr >= 4 && curHr < 12) {
      navigationBackground[0].backgroundImage =
        Environment.serverUrl + _bgImages?.data?.[0]?.imageUrl;
    } else if (curHr >= 12 && curHr <= 20) {
      navigationBackground[0].backgroundImage =
        Environment.serverUrl + _bgImages?.data?.[1]?.imageUrl;
    } else {
      navigationBackground[0].backgroundImage =
        Environment.serverUrl + _bgImages?.data?.[2]?.imageUrl;
    }
    handleBackgroundImage(_location);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize());
  }, []);

  const handleBackgroundImage = (route) => {
    let _route = route.replace(/\/+$/, "");
    let _backgroundImage = navigationBackground.find(
      (item) => item.route === _route
    )?.backgroundImage;
    setPageBackgroundImage(_backgroundImage);
  };

  const handleFloatingButton = () => {
    if (!isFloatingButtonDragable)
      setIsFloatingButtonOpen((previousValue) => !previousValue);
  };

  const handleFloatingClick = (route) => {
    navigate(route);
    handleFloatingButton();
    handleBackgroundImage(route);
  };

  const handleDragable = (event) => {
    if (event.target.getBoundingClientRect().y < 180) {
      setBtnDirection("down");
    } else {
      setBtnDirection("up");
    }
    if (isFloatingButtonDragable) {
      setIsFloatingButtonOpen(false);
      setIsFloatingButtonDragable(false);
    }
  };
  const handleDragleFloatingButton = () => {
    setIsFloatingButtonDragable(true);
    if (isFloatingButtonOpen) setIsFloatingButtonOpen(false);
  };
  const handleCloseDiscountPopup = async () => {
    let _response = await getDiscountBannerStatus()
    if(_response.isSuccess){
      setIsShowDiscountedPopup(false)
    }
  };
  return (
    <div
      className={`${Style.mainContainer}`}
      style={{
        backgroundImage: `url('${pageBackgroundImage}')`,
      }}
    >
      {
          <Popup 
            isOpen={isShowDiscountedPopup}
            width={isMobileTab ? 300 : 800 }
            handleClose={handleCloseDiscountPopup}
          >
            {isMobileTab?
            <img src={DiscountOfferMobile} height={650} width={310} style={{margin: '-39px -34px'}}/>:
            <img src={DiscountOfferWeb} height={470} width={810} style={{margin: '-39px -34px'}}/>}
          </Popup>
      }
      <Box sx={{ display: "flex" }} className={`${Style.container}`}>
        {!isMobileTab && (
          <Box>
            <SideNavbar handleBackgroundImage={handleBackgroundImage} />
          </Box>
        )}
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "transparent" }}
          className={`${Style.outletContainer}`}
        >
          <div>
            <Outlet />
            {_location.replace(/\/+$/, "") !== "/dashboard/messages" &&
              _location.replace(/\/+$/, "") !==
                "/dashboard/virtual-meeting" && (
                <Draggable
                  bounds="body"
                  onDrag={handleDragleFloatingButton}
                  onStop={handleDragable}
                >
                  {/* <FloatingMenu
                    slideSpeed={500}
                    direction={btnDirection}
                    spacing={8}
                    isOpen={isFloatingButtonOpen}
                    className={`${Style.floatingButtonMenu} ${
                      musicList.activePlayerIndex
                        ? Style.floatingBtnPosition
                        : ""
                    } cursor-pointer`}
                  >
                    <MainButton
                      iconResting={
                        <>
                          <div className={Style.chatCounter}>
                            {unseenMessages > 0 && (
                              <div className={`${Style.count} ${Style.count1}`}>
                                <Badge
                                  badgeContent={unseenMessages}
                                  color="error"
                                ></Badge>
                              </div>
                            )}
                          </div>
                          <HeadphoneIcon height={30} width={30} />
                        </>
                      }
                      iconActive={
                        <HeadphoneIcon
                          height={30}
                          width={30}
                          className={`${Style.activeIcon}`}
                        />
                      }
                      className={`${Style.floatingButonn}`}
                      onPointerUp={handleFloatingButton}
                      size={56}
                    />
                    <ChildButton
                      icon={
                        <>
                          <div className={Style.chatCounter}>
                            {unseenMessages > 0 && (
                              <div className={Style.count}>
                                <Badge
                                  badgeContent={unseenMessages}
                                  color="error"
                                ></Badge>
                              </div>
                            )}
                          </div>
                          <ChatIcon />
                        </>
                      }
                      className={`${Style.floatingButonn}`}
                      size={56}
                      onPointerUp={() =>
                        handleFloatingClick("/dashboard/messages")
                      }
                    />
                    <ChildButton
                      icon={<VideoChatIcon />}
                      className={`${Style.floatingButonn}`}
                      size={56}
                      onPointerUp={() =>
                        handleFloatingClick("/dashboard/virtual-meeting")
                      }
                    />
                  </FloatingMenu> */}
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ 
                      position: 'absolute', 
                      bottom: 16, 
                      right: 16, 
                      '& .MuiFab-primary': {
                        backgroundColor: 'white',
                        color: 'white',
                      } 
                    }}
                    icon={
                      <>
                        <div className={Style.chatCounter}>
                          {unseenMessages > 0 && (
                            <div className={`${Style.count} ${Style.count1}`}>
                              <Badge
                                badgeContent={unseenMessages}
                                color="error"
                              ></Badge>
                            </div>
                          )}
                        </div>
                        <HeadphoneIcon height={30} width={30} />
                      </>
                    }
                  >
                    <SpeedDialAction
                      icon={
                        <>
                          <div className={Style.chatCounter}>
                            {unseenMessages > 0 && (
                              <div className={Style.count}>
                                <Badge
                                  badgeContent={unseenMessages}
                                  color="error"
                                ></Badge>
                              </div>
                            )}
                          </div>
                          <ChatIcon />
                        </>
                      }
                      className={`${Style.floatingButonn}`}
                      size={56}
                      onPointerUp={() =>
                        handleFloatingClick("/dashboard/messages")
                      }
                    />
                    <SpeedDialAction
                      icon={<VideoChatIcon />}
                      className={`${Style.floatingButonn}`}
                      size={56}
                      onPointerUp={() =>
                        handleFloatingClick("/dashboard/virtual-meeting")
                      }
                    />
                  </SpeedDial>
                </Draggable>
              )}
          </div>
        </Box>
      </Box>
      {
        isMobileTab &&
        _location.replace(/\/+$/, "") !== "/dashboard/messages" && (
          <BottomNavbar handleBackgroundImage={handleBackgroundImage} />
        )
      }
      {
        _location.replace(/\/+$/, "") === "/dashboard/boost"
        && true ? ( // TODO: change `true` soon to use logic on when it should show up or not (or whether it be hidden or not)
          <AppBar
            className={`${Style.bottomAudioPlayer} py-3`}
            position="fixed"
            color="primary"
            sx={{
              top: "auto",
              bottom: 0,
              transform: {
                xs: playerVisibilityState ? "none" : "translateY(200%)",
                md: playerVisibilityState ? "none" : "translateY(100%)"
              }
            }}
          >
            <div className={`${Style.content}`}>
              <WavesurferModular
                isControlButtons
                isPlayList
                isVolume
                setPlayerDOMReady={setPlayerDOMReady}
                isPlayerInstantiated={wavesurferInstance && playerDOMReady} />
            </div>
          </AppBar>
        ) : null
      }
    </div>
  );
};

export default Dashboard;
